<template>

<div>



  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Login</p>

      </header>
      <section class="modal-card-body">
        <b-field label="Email">
          <b-input
              type="email"
              v-model="mail"
              placeholder="Your email"
              required>
          </b-input>
        </b-field>

        <b-field label="Password">
          <b-input
              type="password"
              v-model="password"
              password-reveal
              placeholder="Your password"
              required>
          </b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button type="submit" @click="loginMe" >
          Connexion
        </b-button>
        <b-button style="margin-right:20px;" @click="$router.push('/')">
          Retour
        </b-button>
      </footer>
    </div>
  </form>

</div>
</template>

<script>
import {getCookie, setCookie} from "@/utils/cookies";
import {login} from "@/utils/user/login";

export default {
  name: "Credentials",
  data() {
    return {
      name: "",
      mail: "",
      password: "",
    };
  },
  beforeCreate() {
    if (getCookie("token")) {
      this.$router.push("/");
    }
  },

  methods : {
    loginMe() {
      login(this.mail.trim(), this.password)
          .then((res) => {
            setCookie("token", res.data.token, 99999999999999999);
            this.notificationSuccess("Vous êtes bien connecté !")
            this.$router.push("/update");
          })
    },
  }

}
</script>
